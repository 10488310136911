import React, { useEffect, useRef, useState } from 'react';
import { ArrowCircleLeft2, MessageText1 } from 'iconsax-react';
import { ReactComponent as Bed } from '../../Assets/Icons/bedcardIcon.svg';
import { ReactComponent as Shower } from '../../Assets/Icons/ShowerIcon.svg';
import { ReactComponent as Toilet } from '../../Assets/Icons/toiletIcon.svg';
import { ReactComponent as Caution } from '../../Assets/Icons/caution.svg';
import { ReactComponent as Step1 } from '../../Assets/Icons/buystep1.svg';
import { ReactComponent as Step2 } from '../../Assets/Icons/buystep2.svg';
import { ReactComponent as Step3 } from '../../Assets/Icons/buystep3.svg';
import { ReactComponent as PropertyOverview } from '../../Assets/Icons/propertyOverview.svg';
import { ReactComponent as Document } from '../../Assets/Icons/document.svg';
import { ReactComponent as Download } from '../../Assets/Icons/download.svg';
import Support from '../../Assets/Icons/support.png';

import { useLocation, useNavigate } from 'react-router-dom';
import BuyProperty from '../../Components/Wallet/BuyProperty';
import { useSingleProperty } from '../../Services/hooks/SingleProperty';
import { PageLoader } from '../../Components/Loader/pageLoader';
import { formatCurrency } from '../../Utils/currencyFormat';
import { usePayment } from '../../Services/hooks/payment';
import { shortenSentence } from '../../Utils/shorttenText';
import { StorageBaseURL } from '../../Services/constant/Action';

function Viewproperty() {
  const { getInvestmentInfo  } = usePayment();
  const datata = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  let pageName = location.pathname;
  pageName = pageName.split('/').pop() || '';
  const { getSinglePropertyData, loading: pageLoading, propertyDetails } = useSingleProperty();
  console.log(propertyDetails?.property);

  const [buy, setBuy] = useState(false);
  const buySlot = async () => {
    const response = await getInvestmentInfo(pageName);
    console.log(response);
    if (response) {
      datata.current = response;
      setBuy(true);
    }
  };

  useEffect(() => {
    getSinglePropertyData(pageName);
  }, []);
  return (
    <div className='py-10'>
      {!buy ? <div className='w-11/12 mx-auto'>
        <div className='flex items-center gap-2 py-4 cursor-pointer'>
          <ArrowCircleLeft2 size="42" variant="Bold" color="#20996B" onClick={() => navigate(-1)}/>
        </div>
        <div className='rounded-3xl mx-auto py-6'>
          {
            pageLoading ?
                <div className='gap-7 w-fit justify-center mx-auto mt-7 flex flex-wrap h-52'>
                  <PageLoader/>
                </div>
              :
                <>
                  <div className={'flex flex-col gap-y-5'}>
                    <div className={'flex lg:flex-row flex-col items-center gap-5'}>
                      <div className={'lg:w-1/2 w-full'}>
                        <img src={propertyDetails?.property?.thumbnail} alt='property' className={'w-full bg-green-200 rounded-xl'}
                             style={{ height: '27rem' }}/>
                      </div>
                      <div className={'lg:w-1/2 w-full'}>
                        <div className='flex w-full flex-row flex-wrap gap-5 justify-center overflow-hidden img'>
                          {
                            propertyDetails?.property?.image?.slice(0, 4).map((imagePath: any, index: number) => {
                              return (
                                  <img key={index} src={imagePath.image} alt='placeholder'
                                       className='max-w-30% max-h-30% xl:w-[48%] w-[20%] lg:max-w-[35%] xl:h-[13rem] lg:h-[10rem] rounded-xl'/>
                              );
                            })
                          }
                        </div>
                      </div>
                    </div>
                    <div className={'flex md:flex-row flex-col justify-between gap-5'}>
                      <div className={'md:w-[65%] w-full rounded-2xl bg-white px-5 p-4 pb-12'}>
                        <h1 className=' text-lg lg:text-2xl font-bold py-4'>
                          {shortenSentence(propertyDetails?.property?.details?.property_title)}
                        </h1>
                        <div className='p-3 pb-4 pl-0 flex flex-col gap-y-3'>
                          <div className={'flex gap-2 '}>
                          <span
                              className={'bg-custom-grey flex justify-between gap-3 py-1 px-2.5 w-fit rounded-3xl items-center text-xs'}>
                            <span>
                              <Bed/>
                            </span>
                            <span>{propertyDetails?.property?.details?.rooms_no}</span>
                          </span>
                            <span
                                className={'bg-custom-grey flex justify-between gap-3 py-1 px-2.5 w-fit rounded-3xl items-center text-xs'}>
                            <span>
                              <Shower/>
                            </span>
                            <span>{propertyDetails?.property?.details?.toilets_no}</span>
                          </span>
                            <span
                                className={'bg-custom-grey flex justify-between gap-3 py-1 px-2.5 w-fit rounded-3xl items-center text-xs'}>
                            <span>
                              <Toilet/>
                            </span>
                            <span>{propertyDetails?.property?.details?.toilets_no}</span>
                          </span>
                            <span
                                className={`${propertyDetails?.property?.available_slots === 0 ? 'text-red-600' : 'text-green-600'} uppercase bg-custom-grey font-semibold rounded-full text-xxs md:text-xs py-1 px-1.5 md:py-2 md:px-4 `}>
                              {propertyDetails?.property?.available_slots === 0 ? 'Sold Out' : 'Active'}
                            </span>
                          </div>
                        </div>
                        <div className={'border-t border-custom-grey flex-col flex gap-y-6'}>
                          <div className={'flex flex-col gap-y-2.5'}>
                            <div className={'flex gap-2 text-xl font-semibold items-center py-3'}>
                              <Caution/>
                              <p>How it works</p>
                            </div>
                            <div className={'flex md:flex-row flex-col gap-2'}>
                              <div className={'border border-custom-grey flex flex-col gap-y-1 rounded-xl p-2'}>
                                <h1 className={'text-custom-lemongreen font-semibold'}>
                                  Step 1
                                </h1>
                                <div className={'flex justify-between items-center'}>
                                  <p className={'font-light text-xs w-1/2'}>Invest in a piece
                                    of this property</p>
                                  <span className={'bg-[#F4F4F4] p-3 rounded-full'}>
                                    <Step1/>
                                </span>
                                </div>
                                <p className={'text-xxs'}>View ownership documents</p>
                              </div>
                              <div className={'border border-custom-grey flex flex-col gap-y-1 rounded-xl p-2'}>
                                <h1 className={'text-custom-lemongreen font-semibold'}>
                                  Step 2
                                </h1>
                                <div className={'flex justify-between items-center'}>
                                  <p className={'font-light text-xs w-1/2'}>Earn rental returns </p>
                                  <span className={'bg-[#F4F4F4] p-3 rounded-full'}>
                                    <Step2/>
                                </span>
                                </div>
                                <p className={'text-xxs'}>We manage everything for you</p>
                              </div>

                              <div className={'border border-custom-grey flex flex-col gap-y-1 rounded-xl p-2'}>
                                <h1 className={'text-custom-lemongreen font-semibold'}>
                                  Step 3
                                </h1>
                                <div className={'flex justify-between items-center'}>
                                  <p className={'font-light text-xs w-1/2'}>Watch your
                                    investment grow</p>
                                  <span className={'bg-[#F4F4F4] p-3 rounded-full'}>
                                    <Step3/>
                                </span>
                                </div>
                                <p className={'text-xxs'}>Gain from value appreciation</p>
                              </div>

                            </div>
                          </div>
                          <div className={'flex flex-col gap-y-2.5'}>
                            <div className={'flex gap-2 text-xl font-semibold items-center py-3'}>
                              <PropertyOverview/>
                              <p>Property Overview</p>
                            </div>
                            <div className={'px-1'}>
                              <p
                                  className='text-xxs md:text-xs lg:text-sm'
                                  dangerouslySetInnerHTML={{ __html: propertyDetails?.property?.details?.details }}
                              />
                            </div>
                          </div>
                          <div className={'flex flex-col gap-y-2.5'}>
                            <div className={'flex gap-2 text-xl font-semibold items-center py-3'}>
                              <Document/>
                              <p>Document</p>
                            </div>
                            <div className={'flex flex-col gap-y-2.5'}>
                              <div
                                  className={'flex justify-between items-center border border-custom-grey text-xs font-light px-2.5 py-2 rounded'}>
                                <p>C of O</p>
                                <a href={`${StorageBaseURL}/${propertyDetails?.property?.document[0]?.document}`} target='_blank' download>
                                  <Download/>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className={'flex flex-col gap-2.5'}>
                            <p className={'text-xl font-semibold'}>Have more questions about this property?</p>
                            <div className={'flex gap-3'}>
                              <img src={Support} alt={'support'} className={'h-[15%] w-[15%]'}/>
                              <div className={'font-light text-xs flex flex-col gap-y-2'}>
                                <p>
                                  Contact our real estate experts
                                </p>
                                <div className={'flex justify-center w-fit px-4 gap-2 items-center border rounded-xl py-2'}>
                                  <MessageText1 size="15" color="#003D20" variant="Bold"/>
                                  <span>Message Us</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={'md:w-[35%] w-full px-5 py-2 pt-28 pb-14 rounded-2xl bg-white flex-col justify-center flex items-center h-fit gap-y-28'}>
                        <div className={'flex flex-col gap-y-2.5 w-full'}>
                          <p className={'text-sm font-light text-center'}>
                            Property Price
                          </p>
                          <p className='font-bold text-sm text-center md:text-xl xl:text-3xl lg:text-2xl'>{formatCurrency(propertyDetails?.property?.total_investment_amount)}</p>
                          {/*<p className='text-center text-xxs lg:text-sm text-custom-lemongreen'>Annual*/}
                          {/*  Return:{formatCurrency(propertyDetails?.property?.earning_on_slot || 0)}</p>*/}
                          <div className={'w-full'}>
                            <div
                                className={`w-full  rounded-full h-2 ${propertyDetails?.property?.percent_funded === 100 ? 'bg-red-600' : 'bg-[#3AC26733]'}`}
                                style={{ mixBlendMode: 'multiply' }}>
                              <div
                                  className={` h-2 rounded-full ${propertyDetails?.property?.percent_funded === 100 ? 'bg-red-600' : 'bg-[#3AC267]'}`}
                                  style={{ width: `${propertyDetails?.property?.percent_funded}%` }}></div>
                            </div>
                            <div className={'flex justify-between w-full text-xxs text-custom-darkgreen pt-0.5'}>
                              <p>
                                {formatCurrency(propertyDetails?.property?.total_amount_invested || 0)} Invested
                              </p>
                              <p>
                                {`${propertyDetails?.property?.available_slots} ${propertyDetails?.property?.available_slots <= 1 ? 'slot' : 'slots'}`} remaining
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className={'w-full flex flex-col gap-y-5'}>
                          <div className={'bg-[#F9F9F9] p-3 py-6 rounded-2xl flex flex-col gap-y-3 w-full'}>
                            <div className='flex justify-between text-sm'>
                              <span>10 year total return</span>
                              <span
                                  className={'font-semibold text-[#5E6473]'}>{propertyDetails?.property?.ten_year_total_return || 0}%</span>
                            </div>
                            <div className='flex justify-between text-sm'>
                              <span>Yearly investment return</span>
                              <span
                                  className={'font-semibold text-[#5E6473]'}>{propertyDetails?.property?.yearly_investment_return || 0}%</span>
                            </div>
                            <div className='flex justify-between text-sm'>
                              <span>Projected net yield</span>
                              <span
                                  className={'font-semibold text-[#5E6473]'}>{propertyDetails?.property?.projected_net_yield || 0}%</span>
                            </div>
                          </div>
                          <div onClick={buySlot}
                               className={`bg-custom-lemongreen cursor-pointer rounded-xl w-[60%] mx-auto text-center text-white font-semibold py-2.5 px-8 ${propertyDetails?.property?.available_slots === 0 ? 'hidden' : ''}`}>
                            Buy a Slot
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </>
          }
        </div>
          </div> :
          <BuyProperty investmentDetails={datata.current} onHide={() => setBuy(false)}/>}
    </div>
  );
}

export default Viewproperty;
