import React from 'react';

function Footer() {
  return (
    <div className='bg-white w-'>
      <div className='lg:w-11/12 mx-auto'>
        <p className='text-sm p-4'>2024 © kekere.</p>
      </div>
    </div>
  );
}

export default Footer;
