import React from 'react';
// import Navbar from "../Components/Layout/Navbar";
// import AboutImg1 from '../../Assets/Images/aboutimg1.svg';
// import AboutImg2 from '../../Assets/Images/aboutimg2.svg';
// import AboutImg3 from '../../Assets/Images/aboutimg3new.svg';
// import AboutImg4 from '../../Assets/Images/aboutimg4new.svg';
import { ReactComponent as TicketIcon } from '../../Assets/Icons/ticketicon.svg';
import { ReactComponent as SpeakerIcon } from '../../Assets/Icons/speakericon.svg';
import { ReactComponent as DesktopIcon } from '../../Assets/Icons/desktopicon.svg';
import { ReactComponent as GitIcon } from '../../Assets/Icons/giticon.svg';
import { ReactComponent as Cancel } from '../../Assets/Icons/greencancel.svg';

import { ReactComponent as FAQ } from '../../Assets/Images/Faq.svg';
import { Disclosure } from '@headlessui/react';
import { Add } from 'iconsax-react';
// import Customer from '../../Components/Homepage/Customer';
import Advert from '../../Components/Homepage/Advert';
import Footer from '../../Components/Layout/Footer';
import backgroundImage from '../../Assets/Images/easybg.png';
// import { Link } from 'react-router-dom';


function About() {
  const value = [
    {
      icon: TicketIcon,
      title: 'Residential Sales and Purchases',
      info: 'From luxury estates to starter homes, we assist clients in finding their ideal residential properties and negotiate the best deals.',
    },
    {
      icon: SpeakerIcon,
      title: 'Co-Owning Services',
      info: 'You can secure a perfect investment plan by co-owning a building with kekere.',
    },
    {
      icon: DesktopIcon,
      title: 'Renting services',
      info: 'kekere offers a convenient, pocket friendly renting service.',
    },
    {
      icon: GitIcon,
      title: 'Property Management',
      info: 'Entrust the management of your investment properties to Kekere Real Estate Company, where we handle everything from tenant screening to maintenance, ensuring peace of mind for property owners.',
    },


  ];

  const containerStyle: React.CSSProperties = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };
  return (
      <div>
        {/*<Navbar />*/}
        <section className={'text-center service-image py-32'}
                 style={{ backgroundImage: 'url(\'https://framerusercontent.com/images/xruPOZj0qJuQ025zmQ72tatw1NE.png\')' }}>
          <h1 className={'text-custom-green'}>Our Mission</h1>
          <p className={' font-bold'} style={{ fontSize: '4rem', lineHeight: 1.1 }}>Empowering everyone <br/>to <span
              className={'text-custom-green'}>own</span> and build wealth<br/> through <span
              className={'text-custom-green'}>Real Estate</span></p>
        </section>
        <section className={'text-center py-52'} style={containerStyle}>
          <h1 className={'text-custom-green'}>Our Vision</h1>
          <p className={' font-bold'} style={{ fontSize: '3rem', lineHeight: 1.1 }}>A world where Real Estate
            is <br/> freely accessible, borderless, <br/> and liquid</p>
        </section>
        <div className='md:w-11/12 lg:w-11/12 xl:w-11/12 md:mx-auto py-10'>
          {/*<div className='flex md:flex-row flex-col justify-between gap-3.5 py-10 md:w-full w-10/12 mx-auto'>*/}
          {/*  <h1 className='text-4xl font-bold w-full md:w-6/12 lg:w-4/12'>*/}
          {/*                Who we are*/}
          {/*  </h1>*/}
          {/*  <div className='w-full md:w-6/12 flex flex-col gap-y-6'>*/}
          {/*    /!*<p className='font-bold'>*!/*/}
          {/*    /!*                Welcome to Kekere Real Estate Company*!/*/}
          {/*    /!*</p>*!/*/}
          {/*    <p>*/}
          {/*      At Kekere, we are passionate about connecting people with their ideal properties. Our team is dedicated to providing personalized real estate solutions that meet the diverse needs of our clients. With years of experience in the industry, we pride ourselves on our integrity, transparency, and commitment to excellence.            </p>*/}
          {/*    <Link to='/register' className='bg-custom-darkgreen px-2 py-2 text-white w-max'>*/}
          {/*                    Get Started*/}
          {/*    </Link>*/}
          {/*  </div>*/}
          {/*</div>*/}
          {/*<section>*/}
          {/*  <div className='flex justify-between gap-3.5 my-3.5 overflow-hidden'>*/}
          {/*    <div className='lg:w-8/12'>*/}
          {/*      <img src={AboutImg1} alt='house' className='h-full object-cover' />*/}
          {/*    </div>*/}
          {/*    <div className='lg:w-4/12'>*/}
          {/*      <img src={AboutImg2} alt='house' className='h-full object-cover' />*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*  <div className='flex justify-between gap-3.5 overflow-hidden'>*/}
          {/*    <div className='lg:w-4/12'>*/}
          {/*      <img src={AboutImg3} alt='house' className='h-full object-cover' />*/}
          {/*    </div>*/}
          {/*    <div className='lg:w-8/12'>*/}
          {/*      <img src={AboutImg4} alt='house' className='h-full object-cover' />*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</section>*/}

          <section className='py-10'>
            <h1 className='font-bold text-4xl text-center pb-10'>Our Services</h1>
            {/*<p className='text-center text-xs md:text-sm w-8/12 mx-auto py-10'>*/}
            {/*              Empowering dreams through exceptional real estate experiences, fostering trust, and building lasting relationships. We aim to exceed the expectations of our clients by delivering exceptional results, whether you're buying, selling, renting or leasing properties.*/}
            {/*</p>*/}
            <div className='flex md:flex-row flex-col gap-3.5 w-10/12 md:w-full mx-auto'>
              {
                value.map((item, index) => {
                  return (
                      <div className='p-3 px-4 border-custom-grey border md:w-3/12' key={index}>
                    <span className='rounded-full p-2 my-3.5 flex flex-col justify-center items-center w-fit'
                          style={{ backgroundColor: '#F8FAFC' }}>
                      <item.icon/>
                    </span>
                        <h1 className='font-bold text-xl pt-3.5 pb-1.5'>
                          {item.title}
                        </h1>
                        <p className='text-sm'>
                          {item.info}
                        </p>
                      </div>
                  );
                })
              }


            </div>
          </section>
          <section className='py-10 flex justify-between bg-custom-white px-10'>
            <div className='w-5/12 hidden md:block'>
              <FAQ className='w-full'/>
            </div>
            <div className='w-full md:w-6/12'>
              <h1 className='pb-5 font-bold text-xl'>
                Frequently Asked Questions
              </h1>
              <Disclosure>
                {({ open }) => (
                    <>
                      <Disclosure.Button
                          className='flex justify-between items-center font-bold border-t-2 py-3.5 w-full text-start'
                          style={{ borderColor: '#E5EAF9' }}>What is kekere about? {open ? <Cancel/> :
                          <Add size="24" color="#20996B"/>}</Disclosure.Button>
                      <Disclosure.Panel>
                        <p className='text-sm py-3'>kekere is a property listing, property sharing, buying investment
                          company in Nigeria and Abroad</p>
                      </Disclosure.Panel>
                    </>
                )}
              </Disclosure>
              <Disclosure>
                {({ open }) => (
                    <>
                      <Disclosure.Button
                          className='flex justify-between items-center font-bold border-t-2 py-3.5 w-full text-start'
                          style={{ borderColor: '#E5EAF9' }}>Who is kekere for? {open ? <Cancel/> :
                          <Add size="24" color="#20996B"/>}</Disclosure.Button>
                      <Disclosure.Panel>
                        <p className='text-sm py-3'>Kekere is for people that are looking at investing in properties to
                          co-own, buy, at affordable and convincing prices with a competitive interest rate. Kekere also
                          offers listing services for Agents and landlords to put up their properties. We also offer
                          Renting services to customers. </p>
                      </Disclosure.Panel>
                    </>
                )}
              </Disclosure>
              <Disclosure>
                {({ open }) => (
                    <>
                      <Disclosure.Button
                          className='flex justify-between items-center font-bold border-t-2 py-3.5 w-full text-start'
                          style={{ borderColor: '#E5EAF9' }}>How can i start with property investment? {open ? <Cancel/> :
                          <Add size="24" color="#20996B"/>}</Disclosure.Button>
                      <Disclosure.Panel>
                        <p className='text-sm py-3'>You can start by signing up on our website and navigate through the
                          features and properties.</p>
                      </Disclosure.Panel>
                    </>
                )}
              </Disclosure>
              <Disclosure>
                {({ open }) => (
                    <>
                      <Disclosure.Button
                          className='flex justify-between items-center font-bold border-t-2 py-3.5 w-full text-start'
                          style={{ borderColor: '#E5EAF9' }}>What types of properties does Kekere Real Estate specialize
                        in? {open ? <Cancel/> : <Add size="24" color="#20996B"/>}</Disclosure.Button>
                      <Disclosure.Panel>
                        <p className='text-sm py-3'>Kekere Real Estate specializes in a wide range of properties
                          including
                          residential homes, luxury estates, investment properties, and vacant land.</p>
                      </Disclosure.Panel>
                    </>
                )}
              </Disclosure>
              <Disclosure>
                {({ open }) => (
                    <>
                      <Disclosure.Button
                          className='flex justify-between items-center font-bold border-t-2 py-3.5 w-full text-start'
                          style={{ borderColor: '#E5EAF9' }}>How can I start the process of buying a property with Kekere
                        Real Estate? {open ? <Cancel/> : <Add size="24" color="#20996B"/>}</Disclosure.Button>
                      <Disclosure.Panel>
                        <p className='text-sm py-3'>Starting the buying process with Kekere Real Estate is easy! Simply
                          register on our website to explore varieties of properties or reach out to us via phone,
                          email.
                          We'll discuss your preferences, budget, and any specific requirements you have for your ideal
                          property. </p>
                      </Disclosure.Panel>
                    </>
                )}
              </Disclosure>
              <Disclosure>
                {({ open }) => (
                    <>
                      <Disclosure.Button
                          className='flex justify-between items-center font-bold border-t-2 py-3.5 w-full text-start'
                          style={{ borderColor: '#E5EAF9' }}>Does Kekere Real Estate offer property management
                        services? {open ? <Cancel/> : <Add size="24" color="#20996B"/>}</Disclosure.Button>
                      <Disclosure.Panel>
                        <p className='text-sm py-3'>Yes, Kekere Real Estate offers comprehensive property management
                          services for both residential and commercial properties. Our team can handle everything from
                          tenant screening and rent collection to maintenance and property inspections, providing you
                          with
                          peace of mind as a property owner. </p>
                      </Disclosure.Panel>
                    </>
                )}
              </Disclosure>
              <Disclosure>
                {({ open }) => (
                    <>
                      <Disclosure.Button
                          className='flex justify-between items-center font-bold border-t-2 py-3.5 w-full text-start'
                          style={{ borderColor: '#E5EAF9' }}>I'm interested in selling my property. How can Kekere Real
                        Estate assist me? {open ? <Cancel/> : <Add size="24" color="#20996B"/>}</Disclosure.Button>
                      <Disclosure.Panel>
                        <p className='text-sm py-3'>When you choose Kekere Real Estate to sell your property, you can
                          expect a personalized and strategic approach to marketing and selling your property. Our
                          agents
                          will conduct a thorough market analysis, create a customized marketing plan, and negotiate the
                          best possible terms on your behalf to ensure a successful sale. </p>
                      </Disclosure.Panel>
                    </>
                )}
              </Disclosure>
              <Disclosure>
                {({ open }) => (
                    <>
                      <Disclosure.Button
                          className='flex justify-between items-center font-bold border-t-2 py-3.5 w-full text-start'
                          style={{ borderColor: '#E5EAF9' }}>What sets Kekere Real Estate apart from other real estate
                        companies? {open ? <Cancel/> : <Add size="24" color="#20996B"/>}</Disclosure.Button>
                      <Disclosure.Panel>
                        <p className='text-sm py-3'>At Kekere Real Estate, we pride ourselves on our integrity,
                          professionalism, and dedication to exceeding client expectations. Our team of experienced
                          agents
                          brings a wealth of knowledge and expertise to every transaction, ensuring that our clients
                          receive the highest level of service and support throughout their real estate journey. </p>
                      </Disclosure.Panel>
                    </>
                )}
              </Disclosure>
            </div>
          </section>
        </div>
        {/*<Customer />*/}
        <Advert/>
        <Footer/>
      </div>
  );
}

export default About;
